import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';

import Aos from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  const [text, setText] = useState(
    'EnGNaJi3MSrugVkVe7UUZRNUrEtpRj71TNnjXptZvU91',
  );

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    textarea.select();
    textarea.setSelectionRange(0, 99999);

    document.execCommand('copy');

    document.body.removeChild(textarea);

    // alert('Token Address Copied: ' + text);
  };

  useEffect(function () {
    Aos.init({ disable: 'mobile' });
  }, []);

  return (
    <>
      <section>
        <div className="container position-relative">
          <div className="section-wrapper bg-1">
            <div className="row justify-content-center">
              <div className="col-md-7">
                <h1 className="h1">Dogslerf</h1>
                <p className="solana mb-0">on SOLANA</p>
              </div>
            </div>

            <div className="position-relative z-1">
              <div className="row justify-content-center mt-md-0 mt-5">
                <div className="col-md-6">
                  <div className="sp-1">
                    <img src="assets/sp-ic.png" className="sp-ic" alt="" />
                    <p>
                      You fucked up the pre-sale, Slerf, but it's fine - happy
                      to be your meme dawg. Solana transactions are slow af...
                      I'll take a nap; nothing will happen overnight.
                    </p>
                  </div>
                  <div className="sp-2">
                    <h4>You lazy ass dog!</h4>
                  </div>
                  <img
                    src="assets/frog.png"
                    className="frog d-md-none d-block"
                    alt=""
                  />

                  <div className="position-relative text-center mt-md-5">
                    <a
                      href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=EnGNaJi3MSrugVkVe7UUZRNUrEtpRj71TNnjXptZvU91&outputSymbol=DOGSLERF&fixed=in"
                      className="primary-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BUY NOW
                      <img src="assets/btn-ic.svg" className="btn-ic" alt="" />
                    </a>
                    <div className="social-icons">
                      <a
                        href="https://twitter.com/Dogslerf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/x.svg" alt="" />
                      </a>
                      <a
                        href="https://t.me/Dogslerf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/tel.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/frog.png"
            className="frog d-md-block d-none"
            alt=""
          />
        </div>
      </section>
      <section>
        <div className="container">
          <div className="marqeee">
            <Marquee>
              <div className="d-flex gap-3">
                <span className="m1">$Dogslerf</span>
                <span className="m2">$Dogslerf</span>
                <span className="m3">$Dogslerf</span>
                <span className="m1">$Dogslerf</span>
                <span className="m2">$Dogslerf</span>
                <span className="m3">$Dogslerf</span>
                <span className="m1">$Dogslerf</span>
                <span className="m2">$Dogslerf</span>
              </div>
            </Marquee>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="section-wrapper pb-md-5">
            <div className="row align-items-end pb-md-5">
              <div className="col-md-4 order-md-0 order-1 position-relative offset-md-1">
                <div className="text-md-end text-center smile-img">
                  <img src="assets/smile.png" className="w-100 smile" alt="" />
                </div>
              </div>
              <div className="col-md-6 mb-md-0 mb-4">
                <div className="about-card">
                  <h2>
                    ABOUT <br /> DOGSLERF
                  </h2>
                  <p>
                    Slerf emerged with a vision to become a fun and engaging
                    meme coin, filled with captivating content to drive its
                    journey forward. However, on launch day, disaster struck
                    when a significant mishap led to the accidental burning of
                    $10 million, which were intended for distribution among
                    25,000 trusting presale buyers. <br />
                    Inspired by the lore of Slerf, a new meme coin emerges: the
                    Dog of Slerf. In contrast to Slerf's ambitions, The dawg
                    embodies the essence of laziness, akin to a sloth in the
                    world of cryptocurrencies. This meme narrative is shaped by
                    a comically lazy yet adorable mishap.
                  </p>
                </div>
                <div className="dex">
                  <a
                    href="https://www.dextools.io/app/en/solana/pair-explorer/URZQXUpSBEuXd93dHV6bWFjhRrAEU8hm5n7E9FFvA7R?t=1711383214915"
                    className=""
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets/tro.png" alt="" />
                  </a>
                  <a
                    href="https://dexscreener.com/solana/urzqxupsbeuxd93dhv6bwfjhrraeu8hm5n7e9ffva7r"
                    className="dex-img"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets/scr.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container">
          <div className="section-wrapper pb-5">
            <div className="pb-5">
            <div className="tx-text">
            Elon Musk has already made his choice: he stated "I love Kermit" on X to support our champion!
            </div>
            <div className="tx-img">
              <img src="assets/tx.png" className="w-100" alt="" />
            </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="container">
          <div className="section-wrapper bg-2">
            <div className="token">
              <div className="w-500">
                <div className="tokenomics">
                  TOKENOMICS
                  <img src="assets/token-ic.png" className="token-ic" alt="" />
                </div>
              </div>

              {/* <div className="tex">
                TOTAL TAX = 0%
              </div> */}

              <div className="burnt mb-md-5 mb-4">
                <a
                  href="https://solscan.io/tx/43bKiRRcdXEL1rTgzbdMoRSyvdtKJE3fr2Cn51Wv1TTutmXEz7tq6jkFAVDEvXgvwvwm9Ddztc7jvQBPiTuxi5Tx"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src="assets/burnt.png" alt="" />
                </a>
                <a
                  href="https://solscan.io/tx/2NdY2Bwa3S6vbbBiapTqggUYo77KdDEA5u1WpGu3K4cSPPwMqovXpUbfWh5T7ZyZVCELLzgNieStUEDJfqriJL7n"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src="assets/revoked.png" alt="" />
                </a>
              </div>

              <div className="w-600">
                <div className="address">
                  TOKEN ADDRESS <br />
                  <span>
                    {text}{' '}
                    <a
                      href="javascript:void(0)"
                      className="icon d-inline-block ms-2"
                      onClick={copyToClipboard}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.3em"
                        height="1.3em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#77fa4e"
                          d="M408 480H184a72 72 0 0 1-72-72V184a72 72 0 0 1 72-72h224a72 72 0 0 1 72 72v224a72 72 0 0 1-72 72"
                        />
                        <path
                          fill="#77fa4e"
                          d="M160 80h235.88A72.12 72.12 0 0 0 328 32H104a72 72 0 0 0-72 72v224a72.12 72.12 0 0 0 48 67.88V160a80 80 0 0 1 80-80"
                        />
                      </svg>
                    </a>
                  </span>
                </div>

                <div className="supply">
                  Total Supply <br />
                  <span>999,999,999</span>
                  <img src="assets/btn-ic.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container">
          <div className="section-wrapper px-md-5 pt-md-5">
            <div className="row justify-content-center pt-4">
              <div className="col-md-10">
                <h2 className="h2">AIRDROP SEASON ONE ENDS IN
                  12 DAYS. JOIN NOW TO EARN XP!</h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-5 mb-md-0 mb-5">
                <img src="assets/chart.png" className="w-100" alt="" />
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-6 col-9 mx-auto mb-4">
                  <div className="earn">
                    <div className="dot"><img src="assets/d1.svg" height={15} alt="" /> <span>Marketing</span></div>
                    <div className="per">50%</div>
                  </div>
                  </div>

                  <div className="col-md-6 col-9 mx-auto mb-4">
                  <div className="earn">
                    <div className="dot"><img src="assets/d2.svg" height={15} alt="" /> <span>Cex liquidity</span></div>
                    <div className="per">10%</div>
                  </div>
                  </div>

                  <div className="col-md-6 col-9 mx-auto mb-md-0 mb-4">
                  <div className="earn">
                    <div className="dot"><img src="assets/d3.svg" height={15} alt="" /> <span>Airdrop</span></div>
                    <div className="per">35%</div>
                  </div>
                  </div>

                  <div className="col-md-6 col-9 mx-auto mb-md-0 mb-4">
                  <div className="earn">
                    <div className="dot"><img src="assets/d4.svg" height={15} alt="" /> <span>Dex liquidity</span></div>
                    <div className="per">5%</div>
                  </div>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
      </section>      */}

      <section>
        <div className="container">
          <div className="b-1">
            <div className="section-wrapper px-md-5 py-5 m-0">
              <div className="row justify-content-center pt-4">
                <div className="col-md-10">
                  <h2 className="h2">HOW TO BUY</h2>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-md-3 col-11 mx-auto mb-4 text-center">
                  <img src="assets/b1.svg" alt="" />
                  <h3>Download PHANTOM</h3>
                  <p>
                    Create a Phantom Wallet using either a desktop computer or
                    an iOS/Android mobile device. Purchase $SOL on CEX like
                    Coinbase, Binance, KuCoin, etc.
                  </p>
                </div>

                <div className="col-md-3 col-11 mx-auto mb-4 text-center">
                  <img src="assets/b2.svg" alt="" />
                  <h3>
                    Secure <br className="d-md-block d-none" /> your SOL
                  </h3>
                  <p>
                    Now all you've got to do is buy your SOL in the PHANTOM APP,
                    or, alternatively you can use an exchange and deposit using
                    your wallet address.
                  </p>
                </div>

                <div className="col-md-3 col-11 mx-auto mb-4 text-center">
                  <img src="assets/b3.svg" alt="" />
                  <h3>Buy some $DOGSLERF</h3>
                  <p>
                    Now go to Raydium and paste the $Dogslerf Contract Address
                    to swap your SOL.
                  </p>
                </div>

                <div className="col-md-3 col-11 mx-auto mb-4 text-center">
                  <img src="assets/b4.svg" alt="" />
                  <h3>Add to Your Wallet</h3>
                  <p>
                    Now you're all set! Welcome aboard the next rocket to the
                    moon, just import the contract address to view you're
                    holdings and you can track your earnings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="section-wrapper brb-0 pb-md-5">
            <div className="row justify-content-center pb-md-5">
              <div className="col-md-5 mb-md-0 mb-5 text-md-start text-center">
                <h2 className="h1 mb-md-0 mb-3">Socials</h2>
                <p className="solana">Join the Dog Slerf Community</p>

                <div className="d-flex justify-content-md-start justify-content-center gap-4 my-md-5 my-4">
                  <a
                    href="https://twitter.com/Dogslerf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets/x.svg" alt="" />
                  </a>
                  <a
                    href="https://t.me/Dogslerf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets/tel.svg" alt="" />
                  </a>
                </div>

                <a
                  href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=EnGNaJi3MSrugVkVe7UUZRNUrEtpRj71TNnjXptZvU91&outputSymbol=DOGSLERF&fixed=in"
                  className="primary-btn text-uppercase px-3 py-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click to Dog in
                </a>
              </div>
              <div className="col-md-5">
                <img src="assets/dog.png" className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-3">
        <div className="container">
          <div className="sub-footer">
            <div>© 2024 Dogslerf TOKEN ALL RIGHTS RESERVED</div>
            <div>
              MADE WITH <span>❤</span> BY Dogslerf TEAM
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
